<!-- <template>
  <div class="form-container">
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar dark label="Formulario de Constancia de Devolución"></s-toolbar>
          <template>
            <v-container class="pb-0">
              <v-row class="justify-center">
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="DNI" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Nombre del Cliente" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select-definition label="Causa" :options="causas" v-model="formData.causa"
                    placeholder="Seleccione una causa" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select-definition label="Contrato" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select-definition label="Opcion Pago" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Numero de Contrato" />
                </v-col>

                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Domicilio" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Distrito" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Provincia" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Departamento" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="N° Lote" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select-definition label="Proyecto" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text type="number" v-model="formData.monto" step="0.01" required label="Monto" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-date label="Fecha Devolucion"></s-date>
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="N° Numero de Cuenta Bancaria" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Banco" />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-col>
      </v-row>
    </v-container>    
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        causas: [
          { value: "resolucion_contrato", label: "Resolución de contrato" },
          { value: "devolucion_cash", label: "Devolución de cash" },
          { value: "error_transferencia", label: "Error en la transferencia" },
        ],
        formData: {
          causa: null,         
        },
      };
    },
    methods: {
      async submitForm() {
        try {
          const response = await fetch("https://tu-backend/api/generar-docx", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.formData),
          });
  
          if (!response.ok) {
            throw new Error("Error al generar el documento");
          }
  
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
  
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "constancia_devolucion.docx");
          document.body.appendChild(link);
          link.click();
          link.remove();
  
          this.documentGenerated = true; 
          alert("Documento generado exitosamente");
        } catch (error) {
          console.error(error);
          alert("Hubo un problema al generar el documento");
        }
      },
      printDocument() {
        window.print(); 
      },
    },
  };
  </script>
  <style>
  .tran {
      opacity: 0.7 !important;
  }
  </style> -->

<template>
  <div class="form-container">
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar dark label="Formulario de Constancia de Devolución"></s-toolbar>
          <v-container class="pb-0">
            <v-row class="justify-center">
              <v-col lg="4" md="4" cols="6" class="s-col-form">
                <s-select-definition :def="1142" v-model="formData.empresa" return-object label="Empresa"></s-select-definition>
              </v-col>
              <v-col lg="4" md="4" cols="6" class="s-col-form">
                <s-select :items="causas" v-model="formData.causa" item-text="label" item-value="label" label="Causa"
                  placeholder="Seleccione una causa" @input="updateVisibility"/>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col lg="2" md="6" cols="6" class="s-col-form">
                <s-select :items="tipodoc" v-model="formData.tipodoc" item-text="label" item-value="label"
                  label="Tipo  de Documento de Identidad" placeholder="Seleccione un tipo de documento" />
              </v-col>
              <v-col lg="4" md="6" cols="6" class="s-col-form">
                <s-text label="Documento de identidad" type="number" :maxlength="15" :autofocus="true" v-model="formData.document"
                  @keyup.enter.native="search" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-text label="Nombre del Cliente / Razon Social" v-model="formData.cliente" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form" v-if="showMotivo">
                <s-text label="Motivo" v-model="formData.motivo"/>
              </v-col>
              <v-col cols="12" md="6" class="s-col-form" v-if="showFechaMotivo">
                <s-date label="Fecha de motivo" v-model="formData.fechaMotivo" />
              </v-col>              
              <v-col cols="12" md="6" class="s-col-form" v-if="showContrato">
                <s-select :items="contratos" v-model="formData.contrato" item-text="label" item-value="label"
                  label="Contrato" placeholder="Seleccione un contrato" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form" v-if="showOpcionPago">
                <s-select :items="pagos" v-model="formData.opcionPago" item-text="label" item-value="label"
                  label="Opcion de Pago" placeholder="Seleccione una opción de Pago" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form" v-if="showNumeroContrato">
                <s-text label="Numero de Contrato" type="number" v-model="formData.numeroContrato" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-text label="Domicilio" v-model="formData.domicilio"
                  @input="formData.domicilio = formData.domicilio.toUpperCase()" />
              </v-col>              
              <v-col cols="6" class="s-col-text">
                <v-autocomplete clearable :items="itemsUbigee" item-value="GenID" item-text="GenDescription"
                  label="Ubigeo" class="uppercase-text" v-model="formData.ubigeo">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-text label="N° Lote" v-model="formData.lote" @input="formData.lote = formData.lote.toUpperCase()" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-select :items="proyectos" v-model="formData.proyecto" item-text="label" item-value="label"
                  label="Proyecto" placeholder="Seleccione un Proyecto" @input="updateVisibility"/>
              </v-col>
              <v-col lg="2" md="6" cols="6" class="s-col-form" v-if="showCuadrante">
                <s-text label="Cuadrante" v-model="formData.cuadrante"/>
              </v-col>
              <v-col lg="2" md="6" cols="6" class="s-col-form" v-if="showEtapa">
                <s-select :items="etapa" v-model="formData.etapa" item-text="label" item-value="label"
                  label="Etapa" placeholder="Seleccione etapa" />
              </v-col>
              <v-col lg="2" md="6" cols="6" class="s-col-form" v-if="showCuadrante">
                <s-text label="Monto" type="text" v-model="formData.monto"
                  placeholder="Digite monto con decimales (ej. 123.45)" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form" v-if="!showCuadrante">
                <s-text label="Monto" type="text" v-model="formData.monto"
                  placeholder="Digite monto con decimales (ej. 123.45)" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-date label="Fecha Devolución" v-model="formData.fechaDevolucion" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-text label="Banco" v-model="formData.banco" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-text label="N° Numero de Cuenta Bancaria" v-model="formData.cuentaBancaria" />
              </v-col>
              <v-col cols="12" md="6" class="s-col-form">
                <s-text label="N° Numero de CCI" v-model="formData.cci" />
              </v-col>              
            </v-row>
            <v-row align="center" justify="space-around">
              <!-- <v-col cols="12" class="s-col-form text-center"> -->
                <v-btn depressed color="error" @click="clear">Limpiar</v-btn>
              <!-- </v-col>
              <v-col cols="12" class="s-col-form text-center"> -->
                <v-btn depressed color="primary" @click="submitForm">Generar Documento</v-btn>
              <!-- </v-col> -->
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import _sOC from "@/services/CustomerSupport/AtcOperationCommercial";
import _sControl from '@/services/CustomerSupport/AtcCustomerControlService'
import _sSupplier from "@/services/Logistics/SupplierService";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      objType: null,
      itemsUbigee: [],
      document: "",
      person: {
        NtpFullName: "",
        PstName: "",
        PrsDocumentNumber: "",
      },

      formattedMonto: this.value !== null ? this.value.toFixed(2) : "",
      // Opciones de la causa
      causas: [
        { value: "Resolución de contrato", label: "Resolución de contrato" },
        { value: "Devolución de cash", label: "Devolución de cash" },
        { value: "Devolución por pago no autorizado", label: "Devolución por pago no autorizado" },
      ],
      contratos: [
        { value: "Contrato de compraventa", label: "Contrato de compraventa" },
        { value: "Contrato de compraventa de bien futuro", label: "Contrato de compraventa de bien futuro" },
        { value: "Contrato de compromiso de compraventa", label: "Contrato de compromiso de compraventa" },
      ],
      pagos: [
        { value: "Financiado", label: "Financiado" },
        { value: "Contado", label: "Contado" },
      ],
      tipodoc: [
        { value: "DNI", label: "DNI" },
        { value: "CARNET DE EXTRANJERIA", label: "CARNET DE EXTRANJERIA" },
        { value: "RUC", label: "RUC" },
        { value: "PASAPORTE", label: "PASAPORTE" },
      ],
      etapa: [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
      ],
      proyectos: [
        { value: "1", label: "LA ALAMEDA DE MIRAFLORES COUNTRY – IMP" },
        { value: "2", label: "ALAMEDA COUNTRY CLUB" },
        { value: "3", label: "MIRAFLORES BOULEVARD PARK PLAZA" },
        { value: "4", label: "MIRAFLORES BOULEVARD PARK PLAZA CUADRANTE 2" },
        { value: "5", label: "MIRAFLORES COUNTRY CLUB 2" },
        { value: "6", label: "RESIDENCIAL ACACIAS DE MIRAFLORES" },
        { value: "7", label: "LA ARBOLEDA DE MIRAFLORES COUNTRY" },
      ],
      formData: {
        empresa: "",
        document: "",
        cliente: "",
        causa: null,
        contrato: "",
        opcionPago: "",
        monto: null,
        fechaDevolucion: null,
        proyecto: null,
        numeroContrato: "",
        domicilio: null,
        cuentaBancaria: null,
        cci: null,
        banco: null,
        ubigeo: null,
        lote: null,
        motivo: "",
        fechaMotivo: null,
        cuadrante: "",
        etapa: ""
      },
      generatedDocUrl: null,
      validations: {
        empresa: true,
        document: true,
        cliente: true,
        causa: true,
        contrato: true,
        opcionPago: true,
        monto: true,
        proyecto: true,
        numeroContrato: true,
        domicilio: true,
        cuentaBancaria: true,
        cci: null,
        banco: true,
        ubigeo: true,
        lote: true,
      },
      showMotivo: false,
      showFechaMotivo: false,
      showCuadrante: false,
      showEtapa: false,
      showContrato: false,
      showOpcionPago: false,
      showNumeroContrato: false,
    };
  },
  created() {
    _sSupplier.listUbigeo("", this.$fun.getUserID()).then(resp => {
      // console.log("resp ubigeo--> ", resp);
      this.itemsUbigee = resp.data
    })
  },
  watch: {
    value(newVal) {
      this.formattedMonto = newVal !== null ? newVal.toFixed(2) : "";
    },
  },
  methods: {
    updateVisibility() {
      // Mostrar Motivo y Fecha de Motivo si la causa seleccionada es "Devolución por pago no autorizado"
      this.showMotivo = this.formData.causa === "Devolución por pago no autorizado";
      this.showFechaMotivo = this.formData.causa === "Devolución por pago no autorizado";
      this.showContrato = this.formData.causa === "Resolución de contrato";
      this.showOpcionPago  = this.formData.causa === "Resolución de contrato";
      this.showNumeroContrato  = this.formData.causa === "Resolución de contrato";

      // Mostrar Cuadrante y Etapa si el proyecto seleccionado es "MIRAFLORES BOULEVARD PARK PLAZA CUADRANTE 2"
      this.showCuadrante = this.formData.proyecto === "MIRAFLORES BOULEVARD PARK PLAZA CUADRANTE 2";
      this.showEtapa = this.formData.proyecto === "MIRAFLORES BOULEVARD PARK PLAZA CUADRANTE 2";
    },
    search() {
      // console.log("llega");
      // console.log("this.document",this.formData.document);
      this.$emit('enter', event); // Emitir el evento 'enter'
      // this.itemsOC = []
      // this.itemsVisita = []
      // this.itemsCobranza = []
      // this.itemsCommitmentSlope = []
      // this.itemsRequirement = []

      if (!this.formData.document || isNaN(this.formData.document)) {
        this.$toast.error("Por favor, ingresa un número válido.");
        return;
      }

      if (this.formData.document.length > 3) {
        // this.document = this.removeNonNumeric(this.document)
        // console.log("this.document--> ", this.document);
        _sControl.byDocument({ PrsDocumentNumber: this.formData.document }, this.$fun.getUserID()).then(respuesta => {
          // console.log("data byDocument searchcustomer--> ", respuesta.data)
          if (respuesta.data.xFlag == 1) {
            // console.log("llega a sunarp");
            _sSunarp.searchsunarp(this.formData.document, this.$fun.getUserID()).then(resp => {
              // console.log("resp searchsunarp --> ", resp)
              if (resp.status == 200) {
                // this.save(1, resp.data == null ? "" : resp.data.SunName)
                // this.person.NtpName = resp.data.SunName
                // this.person.PrsDocumentNumber = resp.data.SunNumberDocument
                // debido alcambio de api se modifica los valores de respuesta
                // this.save(1, resp.data == null ? "" : resp.data.cReniecNombres)
                this.person.NtpName = resp.data.cReniecNombres
                this.person.PrsDocumentNumber = resp.data.cReniecDni
                //hasta aqui cv
                this.formData.document = resp.data.cReniecDni;
                // this.clickBack();
              }
            },
              (e) => {
                // console.log("data sunat", e.status)
                // this.save(0, e.status == null ? "" : resp.data.SunName)
                this.save(0, e.status == null ? "" : resp.data.cReniecNombres)
                this.person.NtpName = "No se encontraron datos"
                this.person.PrsDocumentNumber = this.document
                this.document = "";
                this.formData.document = this.document ;
                this.formData.cliente =  this.person.NtpName;
                // this.clickBack();
              });
          } else {
            // console.log("interno");
            // this.save(1, respuesta.data == null ? "" : respuesta.data.NtpFullName)
            this.person.NtpName = respuesta.data.NtpFullName
            this.person.PrsDocumentNumber = respuesta.data.PrsDocumentNumber
            this.document = respuesta.data.PrsDocumentNumber;
            this.formData.document = this.document ;
            this.formData.cliente =  this.person.NtpName;
            // this.clickBack();
          }

          // this.itemsOC = respuesta.data.listOC
          // this.itemsVisita = respuesta.data.listVisit
          // this.itemsCobranza = respuesta.data.listCobranzas
          // this.itemsRequirement = respuesta.data.listRequirement
          // console.log("this.itemsRequirement", this.itemsRequirement)

          // if (this.itemsVisita.length > 0) {
          //   this.itemsVisita.forEach(element => {
          //     element.VstDate = element.SecCreate == null ? "" : this.$moment(element.SecCreate).format(this.$const.FormatDateDB);
          //     element.VstHour = element.SecCreate == null ? "" : this.$moment(element.SecCreate).format(this.$const.FormatTimeDB);
          //   });
          // }

          // if (this.itemsCobranza.length > 0) {
          //   this.itemsCobranza.forEach(element => {
          //     element.CobDateRegister = element.CobDateRegister == null ? "" : this.$moment(element.CobDateRegister).format(this.$const.FormatDateTimeDB);
          //     element.CobDateCommitment = element.CobDateCommitment == null ? "" : this.$moment(element.CobDateCommitment).format(this.$const.FormatDateDB);
          //   });
          // }
        })
      }

    },

    validateDecimal(event) {
      const input = event.target.value;
      const decimalRegex = /^[0-9]*\.?[0-9]*$/;

      if (decimalRegex.test(input)) {
        // Permitir el valor si es válido
        this.formattedMonto = input;
        const numericValue = parseFloat(input);
        this.$emit("input", isNaN(numericValue) ? null : numericValue);
      } else {
        // Revertir al valor anterior si no es válido
        event.target.value = this.formattedMonto;
      }
    },
    validate() {
      this.validations.document = this.formData.dni.trim() !== "";
      this.validations.person.NtpName = this.formData.cliente.trim() !== "";
      this.validations.numeroContrato = this.formData.numeroContrato.trim() !== "";
      this.validations.domicilio = this.formData.domicilio.trim() !== "";
      // this.validations.distrito = this.formData.distrito.trim() !== "";
      // this.validations.provincia = this.formData.provincia.trim() !== "";
      // this.validations.departamento = this.formData.departamento.trim() !== "";
      this.validations.ubigeo = this.formData.ubigeo.trim() !== "";
      this.validations.distrito = this.formData.distrito.trim() !== "";
      this.validations.lote = this.formData.lote.trim() !== "";
      this.validations.monto = this.formData.monto.trim() !== "";
      this.validations.banco = this.formData.banco.trim() !== "";
      this.validations.cuentaBancaria = this.formData.cuentaBancaria.trim() !== "";
      this.validations.cci = this.formData.cci.trim() !== "";
    },
    async submitForm() {
      try {
        // console.log("typeBusiness---> ", this.objType.DedAbbreviation);
        // this.validate();

        const datosParaEnviar = {
          empresa: this.formData.empresa.DedAbbreviation,
          document: this.formData.document,
          cliente: this.formData.cliente,
          causa: this.formData.causa,
          contrato: this.formData.contrato,
          opcionPago: this.formData.opcionPago,
          monto: this.formData.monto,
          fechaDevolucion: this.formData.fechaDevolucion,
          proyecto: this.formData.proyecto,
          numeroContrato: this.formData.numeroContrato,
          domicilio: this.formData.domicilio,
          cuentaBancaria: this.formData.cuentaBancaria,
          cci: this.formData.cci,
          banco: this.formData.banco,
          distrito: "",
          provincia: "",
          departamento: "",
          ubigeo: this.formData.ubigeo,
          lote: this.formData.lote,
          motivo: this.formData.motivo,
          fechaMotivo: this.formData.fechaMotivo,
          cuadrante: this.formData.cuadrante,
          etapa: this.formData.etapa,
          // ...this.formData, // Copia todos los campos del formulario
          // empresa: this.formData.empresa.DedAbbreviation, // Solo envía DedAbbreviation
        };

        if (!this.validations.document) {
          alert("El dni del cliente es obligatorio.");
          return;
        }
        if (!this.validations.cliente) {
          alert("El nombre del cliente es obligatorio.");
          return;
        }
        if (!this.validations.numeroContrato) {
          alert("El numero de contrato es obligatorio.");
          return;
        }
        if (!this.validations.domicilio) {
          alert("El domicilio del cliente es obligatorio.");
          return;
        }
        // if (!this.validations.distrito) {
        //   alert("El distrito es obligatorio.");
        //   return;
        // }
        // if (!this.validations.provincia) {
        //   alert("La provincia es obligatoria.");
        //   return;
        // }
        // if (!this.validations.departamento) {
        //   alert("El departamento es obligatorio.");
        //   return;
        // }
        if (!this.validations.lote) {
          alert("El numero de lote es obligatorio.");
          return;
        }
        if (!this.validations.monto) {
          alert("El nomto a devolver es obligatorio.");
          return;
        }
        if (!this.validations.banco) {
          alert("El nombre del banco es obligatorio.");
          return;
        }
        if (!this.validations.cuentaBancaria) {
          alert("La cuenta bancaria del cliente es obligatorio.");
          return;
        }

        // console.log("this.formData.empresa--> ", this.formData.empresa);
        console.log("datosParaEnviar--> ", datosParaEnviar);
        const response = await _sOC.generarDevolucion(datosParaEnviar);
        // console.log("response---> ", response);
        const blob = await response.data;

        // Crear la URL del archivo descargable
        this.generatedDocUrl = window.URL.createObjectURL(blob);

        // Crear un enlace para descargar el archivo
        const link = document.createElement("a");
        link.href = this.generatedDocUrl;
        link.setAttribute("download", "constancia_devolucion.docx");
        document.body.appendChild(link);
        link.click();
        link.remove();

      } catch (error) {
        console.error(error);
        alert("Hubo un problema al generar el documento");
      }
    },
    async clear() {
      formData.empresa.clear();

        formData.document.clear(),
        formData.cliente.clear(),
        formData.causa.clear(),
        formData.contrato.clear(),
        formData.opcionPago.clear(),
        formData.monto.clear(),
        formData.fechaDevolucion.clear(),
        formData.proyecto.clear(),
        formData.numeroContrato.clear(),
        formData.domicilio.clear(),
        formData.cuentaBancaria.clear(),
        formData.cci.clear(),
        formData.banco.clear(),
        formData.ubigeo.clear(),
        formData.lote.clear(),
        formData.motivo.clear(),
        formData.fechaMotivo.clear(),
        formData.cuadrante.clear(),
        formData.etapa.clear()
      },
    },

    printDocument() {
      if (this.generatedDocUrl) {
        const printWindow = window.open(this.generatedDocUrl, "_blank");
        printWindow.print();
      } else {
        alert("Primero genere el documento para imprimirlo");
      }
    },
  
};
</script>

<style>
.form-container {
  margin: 20px;
}

.s-col-form {
  margin-bottom: 15px;
}

.uppercase-text input {
  text-transform: uppercase;
}
</style>


<!-- <style>
  .form-container {
    width: 50%;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  form div {
    margin-bottom: 10px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  button {
    margin-right: 10px;
    padding: 10px 20px;
  }
  </style> -->